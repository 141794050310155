.claims-analysis-container{
    min-width: 180px;
    height: 100%;
    min-height: 150px;
    max-height: 160px;
    flex: 1;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    cursor: default;
    overflow: hidden;
}

.overlay-top{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    top: 0;
}

#cloud-id{
    width: 250%;
    height: 200%;
    color: white;
    position: absolute;
    opacity: 0.25;
    top: -10px;
}