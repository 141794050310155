.countrybudget-container{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 0.3px solid rgba(0, 0, 0, 0.021);
    /* background-color: aqua; */
}

.countrybudget-container > span{
    font-weight: 600;
    color: #000000;
    /* background-color: blue; */
    width: 70px;
}

