.toggle-button{
    /* width: 150px; */
    /* background-color: blue; */
    display: flex;
    align-items: center;
}

.nav-button{
    background-color: white;
    border: none;
    padding: 8px 15px;
    color: #010e31;
    font-weight: 500;
    /* border-radius: 10px; */
    background-color: #010e316b;
    margin-right: 5px;
    outline: none;
    cursor: pointer;
    border: none;
}

.actives{
    font-weight: 700;
}

.activess{
    border: none;
    border: none;
    padding: 8px 15px;
    color: white;
    font-weight: 500;
    /* border-radius: 10px; */
    background-color: #010e31;
    margin-right: 5px;
    outline: none;
    cursor: pointer;
}

.nav-button:hover{
    color: white;
    background-color: #010e3194;
}