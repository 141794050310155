.bnw-exrt-main-container{
    width: 100%;
}

.bnw-promopg-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
    padding: 10px;
    position: relative;
}

.bnw-promopg{
    width: 100%;
    /* min-width: 320px; */
    /* height: 85%; */
    min-height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 10px 10px rgba(59, 130, 246, 0.2);
    padding: 30px 10px 40px 10px;
    border-radius: 5px;
}

.bnw-promopg-exch-table{
    width: 100%;
    min-height: calc(100vh - 280px);
    display: flex;
    flex-direction: column;
    padding: 20px 10px 30px 10px;
    gap: 10px;
    /* background-color: white; */
    /* border-top: 1px solid rgba(136, 136, 136, 0.596); */
}

.bnw-promopg-submits{
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 15px;
    font-weight: 600;
    background-color: #e50000;
    cursor: pointer;
    border: 0;
}

.bnw-promopg > span{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #475366;
}

.bnw-promopg-field-contain{
    width: 100%;
    display: flex;
    align-items: center;
    /* background-color: yellow; */
}

.bnw-promopg-field-1{
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.bnw-promopg-field-1 > span{
    font-size: 12px;
    font-weight: 600;
    margin-right: 20px;
}

.bnw-promopg-field-2{
    width: 50%;
    display: flex;
    /* background-color: aqua; */
}

#amout-per-dollar{
    width: 100%;
    min-width: 200px;
    padding: 10px;
    outline: none;
    border: 1px solid rgba(136, 136, 136, 0.082);
}
