.bnw-addirector-pg{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.bnw-region-drpdwn{
    width: 100%;
    padding: 0px 0px 10px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 0.1px solid rgba(0, 0, 0, 0.281);
    max-height: 150px;
    position: absolute;
    z-index: 100;
    top: 35px;
    overflow-y: scroll;
}

.bnw-region-drpdwn > span{
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
}

.bnw-region-drpdwn > span:hover{
    background-color: rgba(0, 0, 0, 0.192);
}

.main-add-dir-container{
    position: relative;
    z-index: 100;
}

.bnw-addirector-contain{
    width: 40%;
    min-width: 350px;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* max-height: 75vh; */
    background-color: white;
    padding: 20px;
    /* background-color: rebeccapurple; */
}

.bnw-chevrondown{
    position: absolute;
    top: 25%;
    font-size: 15px;
    right: 2%;
    color: rgba(0, 0, 0, 0.616);
    cursor: pointer;
}

.bnw-pswrd-blk{
    display: flex;
    flex-direction: column;
}

.bnw-regionfield{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    width: 100%;
    flex-grow: 1;
}

.bnw-dirname-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    /* background-color: yellow; */
}

#bnw-firname-box{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    width: 200px;
    flex-grow: 1;
    font-weight: 500;
}

#bnw-firname-box_{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    flex-grow: 1;
    /* background-color: green; */
    width: 100%;
}

#bnw-firname-boxx{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    flex-grow: 1;
    /* background-color: green; */
    width: 100%;
}

.bnw-direct-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    position: relative;
}

#bnw-close-icon{
    font-size: 30px;
    color: rgba(51, 51, 51, 0.842);
    cursor: pointer;
    position: absolute;
    right: 0px;
}

.bnw-direct-heading > span{
    font-size: 18px;
    font-weight: 600;
}

#bnw-submit-butn{
    width: 100%;
    padding: 10px;
    background-color: white;
    margin-top: 30px;
    border: 1px solid #e50000;
    background-color: #e50000;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

#bnw-submit-butn:hover{
    background-color: #e50000ab;
}

.top-event-container-body{
    height: fit-content;
    width: 100%;
    /* min-width: 500px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 5px;
    padding: 0px;
    /* background-color: aqua; */
}

.top-event-column1{
    grid-area: column1;
    /* background-color: palevioletred; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 6px;
    gap: 8px;
    width: 50%;
}

.top-event-column1 > span{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}

.top-event-column2{
    /* background-color: bisque; */
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px 6px;
    gap: 8px;
}

.top-event-column2 > span{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}

.my-dateclass{
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    padding: 5px 12px;
}

@media screen and (max-width: 1050px) {

    #bnw-firname-box:last-child{
        margin-top: 10px;
    }
}