.sloadercontainer{
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(255, 255, 255, 0.226);
    display:flex;
    justify-content: center;
    align-items:center;
    position: relative;
}

.sloadingBox{
    width: 8%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    /* height: 15%; */
}

#Loadingtag{
    color: #e50000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}