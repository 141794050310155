.bnw-budget-container{
    width: 31%;
    min-width: 200px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 15px 35px rgba(59, 130, 246, 0.2);
    padding: 20px 30px;
    min-height: 240px;
    gap: 15px;
}

.budget-right-pie-container{
    display: flex;
    /* background-color: aqua; */
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.budget-right-pie-container > span{
    font-size: 15px;
    font-weight: 700;
}

/* #bnw-bud-icon{
    font-size: 25px;
    cursor: pointer;
} */

.bnw-top-budget-contn{
    width: 100%;
    /* background-color: aqua; */
    padding: 3px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bnw-body-budget-contn{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* background-color: aqua; */
}



.left-budget-body-contn{
    width: 75%;
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.drop-down-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    /* background-color: aqua; */
    max-width: 150px;
}

.drop-down-filter > span{
    font-size: 16px;
    font-weight: 700;
}