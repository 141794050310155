.budget-overview-break{
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    gap: 10px;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    /* height: 150px; */
    height: fit-content;
    flex-wrap: wrap;
    /* background-color: aqua; */
}