.Performancecard-container{
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    background-color: #F8F8F9;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Performancecard-bottm{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    margin-top: 10px;
}

.performance-container{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    /* background-color: rebeccapurple; */
}

.Performancecard-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Performancecard-top > span:first-child{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #828181;
}

#perf-icon{
    font-size: 29px;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.performance-container-top{
    width: 30%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* background-color: green; */
}

.performance-container-top > span{
    font-weight: 500;
    /* line-height: 15px; */
    /* color: #888; */
    width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
    /* background-color: greenyellow; */
}

.budget-loader-container{
    width: 100%;
    /* background-color: grey; */
    display: flex;
    align-items: center;
    gap: 15px;
}

.budget-loader-container> span{
    font-weight: 600;
    width: 45px;
    /* background-color: blue; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
}


.performance-container-bottom{
    width: 100%;
    height: 4px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #8888881a;
    margin: 2px 0px;
    border-radius: 5px;
    overflow: hidden;
}

.performance-loader{
    position: absolute;
    top: 0;
    height: 100%;
    transition: width 1.5s ease-out;
}
