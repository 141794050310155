.overlay-container{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.171);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}

#input-example{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    width: 200px;
    flex-grow: 1;
    font-weight: 500;
}

.overlay-addemployee-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    gap: 20px;
    width: 45%;
    min-width: 600px;
    max-width: 600px;
    background: #FFFFFF;
    overflow-y: auto;
    min-height: 50vh;
    max-height: 60vh;
}

.top-container-apprais-notif{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
    padding: 5px 0px;
}

.top-container-apprais-notif > span{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

#closeIcon-notifc{
    position: absolute;
    right: 0px;
    font-size: 30px;
    color: red;
    cursor: pointer;
}

#broadcast-buttn{
    padding: 10px;
    gap: 10px;
    background-color: #1EC602;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: white;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    border: #8888;
}

.top-addemployee-container-body{
    /* min-height: 390px; */
    width: 100%;
    /* min-width: 500px; */
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    align-items: center;
    padding: 10px 0px 10px;
    gap: 5px;
    border: 1px solid rgba(212, 212, 212, 0.2);
    border-radius: 15px;
}

.top-addemployee-submit-row{
    width: 100%;
    padding: 6px 15px;
    margin-top: 5px;
}

.form-row-addrow{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
}

.addemployee-addrow{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
}

.addemployee-addrow-cola-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 15px;
}

.addemployee-addrow-cola-cont > span{
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 6px;
}

.addemployee-addrow-cola{
    width: 100%;
    display: flex;
}

@media screen and (max-width: 900px) {
    .overlay-addemployee-container{
        width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .overlay-addemployee-container{
        width: 90%;
        min-width: 310px;
        max-width: 600px;
    }
}

/* Mobile Phone */
@media screen and (max-width: 600px) {
    .overlay-addemployee-container{
        width: 90%;
    }
}

@media screen and (max-width: 550px) {
    .overlay-addemployee-container{
        gap: 15px;
    }

    #broadcast-buttn{
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .overlay-addemployee-container{
        width: 95%;
    }
}
