.navbar-contain{
    /* background-color: brown; */
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

.toggle_contain, .toggle_contain_inv, .toggle_contain-disabled{
    padding: 0px;
    border-radius: 25px;
    min-width: 50px;
    height: 25px;
    background: #BED8FE;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
}

.toggle_contain_inv{
    justify-content: flex-end;
}

.toggle_contain-disabled{
    pointer-events: none;
    opacity: 0.4;
}

#darkmode,  #lightmode{
    height: 100%;
    width: 70%;
    background: #294566;
    border-radius: 15px;
    color:#FFFFFF;
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 0px;
    border: 0px;
}

.toggle_contain_scrn{
    display: none   
}

#contrast_icon{
    color: white;
    font-size: 25px;
}

@media screen and (max-width: 1000px){
    /* .toggle_contain, .toggle_contain_inv{
        display: none
    } */

    .toggle_contain_scrn{
        display: block
    }

    .toggle_contain_scrn > #darkmode{
        height: 40px;
        width: 40px;
        background: linear-gradient(277.23deg, #5B9CFD -8.72%, rgba(228, 235, 244, 0.84) 121.57%);;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items:center;
        font-size: none;
    }
    
    .toggle_contain_scrn > #lightmode{
        position: absolute;
        right: 4.4%;
        left: 36.26%;
        top: 10.53%;
        bottom: 10.53%;
        background: #5B9CFD;
        border-radius: 15px;
        color:#FFFFFF;
        display: flex;
        justify-content: center;
        align-items:center;
        font-size: 13px;
        margin: 0px;
        border: 0px;
    }
    
}

@media screen and (max-Width: 700px) {
    .navbar-contain{
        justify-content: flex-end;
    }
} 