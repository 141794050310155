.body-tab-roww{
  width: 100%;
  justify-content: flex-end;
}

.body-tab-roww > td{
  font-weight: 600;
  /* font-size: 18px; */
  color: white;
  background-color: #e50000;
}

.body-tab-roww > td:last-child{
  font-weight: 600;
  /* font-size: 18px; */
  color: white;
  background-color: #e50000;
  text-decoration: underline overline;
}

.body-tab-rowws{
  width: 100%;
  justify-content: flex-end;
}

.body-tab-rowws > td{
  font-weight: 600;
  /* font-size: 18px; */
  color: white;
  background-color: #e50000;
}

.body-tab-rowws > td:nth-child(3){
  text-decoration: underline overline;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table tr {
    border: 1px solid #ddd;
  }

  table th,
  table td {
    padding: .625em;
    font-size: 14px;
  }

  .body-tab-row > td:nth-child(5){
    font-weight: 600;
  }

  thead > tr{
    /* background-color: #194e91; */
    background-color: #e50000;
  }

  tr > th{
    color: white;
    font-size: 16px;
  }

  tbody > tr:nth-child(even) {
    background-color: #194d9113;
    min-height: 60px;
  }

  .top-tab-container-alloc{
    display: flex;
    width: 100%;
    align-items: center;
  }

  #back-butn-id{
    padding: 10px 20px;
    color: white;
    background-color: #e50000;
    border: 1px solid #e50000;
    min-width: 80px;
    width: 10%;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    border-radius: 2px;
    margin-right: 1px;
  }

  #back-butn-id:hover{
    color: #e50000;
    background-color: #fff2f294;
  }
  
  @media screen and (max-width: 700px) {
    table {
      border: 0;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 13px;
      text-align: right;
    }
    
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }