.boardcard-container{
    min-width: 220px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #F8F8FF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    flex-grow: 1;
    gap: 12px;
    padding: 4px 20px;
}

#id-arrowid{
    font-size: 30px;
    position: absolute;
    color: #828181;
}

.bcrd-left-container{
    width: 160px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bcrd-right-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bcrd-right-container > span:last-child{
    line-height: 26px;
    color: #333;
}

#money-bag{
    margin-bottom: 5px;
    font-size: 40px;
    color: green;
}