.navbarContian{
    width: 100%;
    height: 50px;
    /* position: fixed; */
    background-color: white;
    top: 0;
    display: flex;
    align-items: center;
}

#imgIcon{
    background: url('../../assets/sabre.png') no-repeat;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100%;
    width: 100% ;
}

.navbar-icon{
    width: 130px;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}

.navbarContian > div{
    color: #475366;
    word-spacing: 2px;
    letter-spacing: 1px
}
