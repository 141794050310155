.loadercontainer{
    position: absolute;
    /* top: 0; */
    display:flex;
    justify-content: center;
    align-items:center;
    width: 30%;
    min-width: 250px;
    min-height: 50vh;
    max-height: 65vh;
    background-color: white;
    padding: 20px;
}

#Loadingtag{
    text-align: center;
}

.loadingBox{
    /* border: 1px solid grey; */
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    /* height: 15%; */
}

.bnw-loadercontainer{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(202, 201, 201, 0.911);    
    z-index: 100;
}

.bnw-loadingBox{
    min-width: 150px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bnw-loadingBox > span{
    margin-top: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #e50000;
}